export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'regionCode',
    label: 'field.region',
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
    subKey: 'salespersonIdCard',
    hideToggle: true,
  },
  {
    key: 'workingScheduleName',
    label: 'field.workingSchedule',
    localization: true,
  },
  {
    key: 'workingShift',
    label: 'field.shift',
    hideToggle: true,
  },
  {
    key: 'attendanceDate',
    label: 'field.attendanceDate',
    type: 'date',
    hideToggle: true,
  },
  {
    key: 'checkinLate',
    label: 'field.checkinLate',
    hideToggle: true,
  },
  {
    key: 'checkoutEarly',
    label: 'field.checkoutEarly',
    hideToggle: true,
  },
  {
    key: 'checkinDateTime',
    label: 'field.checkinTime',
    type: 'time',
    tdClass: 'text-success',
    hideToggle: true,
  },
  {
    key: 'checkinImage',
    label: 'field.checkinImage',
    type: 'image',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'checkinAddress',
    label: 'field.checkinAddress',
    type: 'map',
    latitudeKey: 'checkinLatitude',
    longitudeKey: 'checkinLongitude',
  },
  {
    key: 'checkoutDateTime',
    label: 'field.checkoutTime',
    type: 'time',
    tdClass: 'text-danger',
    hideToggle: true,
  },
  {
    key: 'checkoutImage',
    label: 'field.checkoutImage',
    type: 'image',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'checkoutAddress',
    label: 'field.checkoutAddress',
    type: 'map',
    latitudeKey: 'checkoutLatitude',
    longitudeKey: 'checkoutLongitude',
  },
  {
    key: 'remark',
    label: 'field.remark',
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
  },
];
